<script setup lang="ts">
import { Form, Field } from 'vee-validate'
import { handleError } from '@/utils/form'
import IconCheck from '@/assets/icons/check.svg'

const { $restClient } = useNuxtApp()

const initialValues = {
  email: '',
  source: 'source',
}

const { form, handleSubmit, successMessage } = useForm({
  initialValues,
  fetcher: $restClient.requestResetPasswordLink,
  onError: handleError,
})
</script>

<template>
  <Form
    v-slot="{ isSubmitting }"
    data-testid="login-form"
    class="fill-max-width max-w-form-container"
    @submit="handleSubmit"
  >
    <h1 class="mb-3 text-2xl font-bold">Forgot your password?</h1>

    <p class="mb-10 text-lg">
      Enter your email address and we will send you instructions to reset your
      password.
    </p>

    <BaseInput
      v-model="form.email"
      data-testid="email"
      label="Email"
      name="email"
      type="email"
      placeholder="name@email.com"
      class="mb-5"
      rules="required|email"
      is-focused
      :validate-on-blur="false"
    />

    <Field v-model="form.source" type="hidden" name="source" />

    <div
      class="flex flex-col-reverse items-stretch justify-end gap-x-2 md:flex-row"
    >
      <BaseButton variant="text" @click="$modal.open('login')">
        Back to login
      </BaseButton>
      <BaseButton type="submit" :loading="isSubmitting"> Send link </BaseButton>
    </div>

    <div class="mt-5">
      <p v-if="successMessage" class="flex max-w-[280px] gap-2 text-sm">
        <IconCheck class="mt-[.1rem] shrink-0 text-green" />
        {{ successMessage }}
      </p>
    </div>
  </Form>
</template>
